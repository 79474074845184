import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AIRPORTS_JSON_PATH,
  FLIGHT_EDIT_PRICE,
  LAMBDA_API_ACCESS_KEY,
  LAMBDA_API_REGION,
  LAMBDA_API_SECRET_KEY,
  S3_FASTFIX_PATH,
} from 'src/constants/constants';
import { ApiService } from '@app/general/services/api/api.service';
import { Observable } from 'rxjs';
const AWS = require('aws-sdk');
@Injectable({
  providedIn: 'root',
})
export class SharedCommonService {
  constructor(private httpClient: HttpClient, private apiService: ApiService) {}
  baseUrl = this.apiService.apiBaseUrl();
  public getUserCountry() {
    const url = `https://api.country.is/`;
    return this.httpClient.get(url);
  }
  /**Fetching the translations*/
  getAirportsJsonData() {
    const url = `${S3_FASTFIX_PATH}${AIRPORTS_JSON_PATH}`;
    return this.httpClient.get(url);
  }
  public getPriceUpdationEmails(params: any) {
    return this.httpClient.get(this.baseUrl + FLIGHT_EDIT_PRICE, { params });
  }

  public checkRates(data: any) {
    /**we can enable this once checkrates available
     * const url = `${this.baseUrl}${CHECK_RATES}?${this.queryString.getParamApiPath()}`;
     * return this.httpClient.post(url, data);
     */
    return this.httpClient.post('', data);
  }

  /**here we are integrated AWS lambda function for notifications for News-Letter subscribtion*/
  public subscribeToNewsLetter(UserEmail: any, reciverEmail: any) {
    const awsConfig = {
      accessKeyId: `${LAMBDA_API_ACCESS_KEY}`,
      secretAccessKey: `${LAMBDA_API_SECRET_KEY}`,
      region: `${LAMBDA_API_REGION}`,
    };
    AWS.config.update(awsConfig);
    const lambda = new AWS.Lambda();

    const obj = {
      templates_required: { email: 94 },
      notif_params: {
        email: UserEmail,
      },
      contact_info: {
        email: reciverEmail,
      },
    };
    const params = {
      FunctionName: `${this.apiService.getLambdaFunctionUrl()}`,
      Payload: JSON.stringify({ body: JSON.stringify(obj) }),
    };
    lambda.invoke(params, (err: any, data: any) => {
      if (err) {
        console.log('Error invoking Lambda function:', err);
      }
    });
  }
  manageDeeplinks(url: any) {
    return this.httpClient.get(url);
  }
  download(file: string | undefined, link: any): Observable<Blob> {
    return this.httpClient.get(link, {
      responseType: 'blob',
    });
  }
  downloadExcelFiles(filename: any, downloadable_Link: any) {
    this.download(filename, downloadable_Link).subscribe((file) => {
      const a = document.createElement('a');
      a.setAttribute('type', 'hidden');
      a.href = URL.createObjectURL(file);
      a.download = filename + '.xlsx';
      a.click();
      a.remove();
    });
  }
}
